import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2';

import router from './router'
import store from './store'
import App from './App.vue'

// 3rd party plugins
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import Lingallery from 'lingallery';


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faMessage, faEnvelope, faFileCirclePlus, faAudioDescription, faCashRegister, faCircleXmark, faCloudArrowUp, faReceipt, faStar, faCalendarXmark, faCalendarCheck, faBuilding, faStopwatch, faRectangleList, faTable, faCirclePlus, faFileExport, faCalendar, faUserGroup, faWarehouse, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add( faMessage, faEnvelope, faFileCirclePlus, faAudioDescription, faCashRegister, faCircleXmark, faCloudArrowUp, faReceipt, faStar, faCalendarXmark, faCalendarCheck, faBuilding ,faStopwatch, faRectangleList, faTable, faCirclePlus, faFileExport, faCalendar, faUserGroup, faWarehouse, faFileInvoiceDollar )

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Global Components
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

// Try to use pina
// import { createPinia, PiniaVuePlugin } from 'pinia'
// const pinia = createPinia()

Vue.config.productionTip = false

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ElementUI, {locale})

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('lingallery', Lingallery);
// Vue.use(PiniaVuePlugin)

import '@/libs/toastification'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import core styles
// require('@core/scss/core.scss')
import '@/assets/scss/main.scss'



const options = {
  confirmButtonColor: '#eda80a',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options);


new Vue({
  router,
  store,
  // pinia,
  render: h => h(App)
}).$mount('#app')