<template>
<div>
  <a aria-label="Whatsapp" rel="noreferrer"  v-if="$store.state.settings && $store.state.settings.length > 0"
            :href="`https://wa.me/${$store.state.settings[
              findInvoiceIndex('tel')
            ].value
              .split(' ')
              .join('')}`"
            target="_blank" class="cta-whatsapp floating">
  <img src="@/assets/icon/whatsapp-logo.png" class="whatsapp-icon">
</a>
    <home-banner ></home-banner>

    <div class="home-content">
    <home-video />
    <home-promotion />
    <!--<home-news /> -->

    <home-partner />
    </div>
    <home-award />
    
</div>
</template>

<script>

import HomeBanner from './HomeBanner.vue'
import HomeVideo from './HomeVideo.vue'
import HomePromotion from './HomePromotion.vue'
// import HomeNews from './HomeNews.vue-bak'
import HomeAward from './HomeAward.vue'
import HomePartner from './HomePartner.vue'

import { mapGetters } from "vuex";
export default {
  name: 'HomeView',
  components: {
    HomeBanner,
    HomeVideo,
    HomePromotion,
    // HomeNews,
    HomeAward,
    HomePartner,

  },
  computed: {
    ...mapGetters({
      findInvoiceIndex: "findInvoiceIndex",
    }),
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.home-content{
  padding: 0 10%;
}

.heading{
  text-align:left;
 
}

.heading-title{
  margin-left: 10px;
  font-size: 28px;
  color: $logo-text-color;
  font-weight: 700;
}

.heading-subtitle{
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
  color: $text-color;
}

@media only screen and (max-width: 600px) {
.home-content{
  padding: 0;
}
}


.fa-whatsapp{
  margin-inline: 0px;
}
.cta-whatsapp{
	transition: all 0.3s ease;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    position: fixed;
    top:80%;
    // transform:translateY(-50%);
    display: block;
    right: 20px;
    margin: 10px;
    // color: #fff;
    // background: #00e676;
    // background: white;
    // padding: 5px;
    // border-radius: 100%;
    // font-weight: 500;
    z-index: 99;
    &:hover{
    	transition: all 0.3s;
    	opacity: 0.8
    }
}
.floating {
	animation-iteration-count:infinite;
	animation-duration: 1.3s;
	animation-name: floating-float, floating;
	}
	@keyframes floating {
	 0% {
	   -webkit-transform: translateY(-8px);
	   transform: translateY(-8px);
	 }
	 50% {
	   -webkit-transform: translateY(-4px);
	   transform: translateY(-4px);
	 }
	 100% {
	   -webkit-transform: translateY(-8px);
	   transform: translateY(-8px);
	 }
	}
	@keyframes floating-float {
	 100% {
	   -webkit-transform: translateY(-8px);
	   transform: translateY(-8px);
	 }
}

.whatsapp-icon{
  width:50px;
}
</style>
