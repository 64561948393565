import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getBanner(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/banner/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDiscounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/discount/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getVideos(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns.get('/video/list', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
  },
};
