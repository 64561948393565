<template>
  <!-- modal Edit-->

  <b-modal
    id="modal-edit-password"
    cancel-variant="outline-secondary"
    ok-title="修改"
    cancel-title="關閉"
    body-class="common_modal"
    centered
    title="修改 密碼"
    @ok="handleEdit"
    :ok-disabled="false"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form @submit.prevent="handleEdit" ref="resetPasswordForm">
        <b-form-group class="mt-1">
          <label for="old_password">舊密碼：</label>
          <b-form-input
            id="old_password"
            type="password"
            placeholder="輸入舊密碼"
            v-model="userInfo.old_password"
          />
          <small v-if="!userInfo.old_password" class="text-danger"
            >請輸入舊密碼</small
          >
        </b-form-group>
        <b-form-group>
          <label for="password">密碼：</label>
          <b-form-input
            id="password"
            type="password"
            placeholder="輸入密碼"
            v-model="userInfo.password"
          />

          <small v-if="!userInfo.password" class="text-danger"
            >請輸入密碼</small
          >
        </b-form-group>

        <b-form-group>
          <label for="name">確認密碼：</label>
          <b-form-input
            type="password"
            v-model="userInfo.password_confirmation"
            placeholder="輸入確認密碼"
          />
          <small v-if="!userInfo.c_password" class="text-danger"
            >請輸入確認密碼</small
          >
          <small
            v-if="
              userInfo.password_confirmation &&
              userInfo.password !== userInfo.password_confirmation
            "
            class="text-danger"
            >新密碼與確認密碼不相同</small
          >
        </b-form-group>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import axiosIns from "@/libs/axios";
// import ToastificationContent from "@/libs/toastification/ToastificationContent";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BOverlay,
  },
  data() {
    return {
      userInfo: { old_password: "", passowrd: "", password_confirmation: "" },
      loading: false,
    };
  },
  methods: {
    resetForm() {
      this.userInfo = {
        old_password: "",
        passowrd: "",
        password_confirmation: "",
      };
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-edit-passworder", "#btnShow");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();

      this.$store.commit("updateUserData");

      this.$swal({
        text: "你已經成功登出！",
        icon: "success",
        timer: 2000,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });

      // this.$toast({
      //   component: ToastificationContent,
      //   position: "top-right",
      //   props: {
      //     title: `成功`,
      //     icon: "CoffeeIcon",
      //     variant: "success",
      //     text: `您已成功退出！`,
      //   },
      // });
    },
    handleEdit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      axiosIns
        .post("/resetPassword", this.userInfo)
        .then((response) => {
          this.loading = false;
          this.$bvModal.hide("modal-edit-passworder");
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.handleLogout();
        })
        .catch((error) => {
          this.loading = false;

          if (error.response.status == 401) {
            // console.log('self.$store',self.$store)
            localStorage.clear();
            this.$store.commit("updateUserData")
            console.log('401 Unauthorized')

            this.$router.replace('/').then(() => {

              this.$swal.fire({
                text: "你已被登出！",
                confirmButtonText: "確定",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
            return 
          }
          
          const message = error.response.data.message;
          var showMessage = "";
          var showMessage = message;
          if (typeof showMessage === "object") {
            showMessage = "";
            for (var key in message) {
              if (message.hasOwnProperty(key)) {
                showMessage += message[key] + "\n";
              }
            }
          }
          this.$swal({
            text: showMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style></style>
