<template>
  <!--https://ssense.github.io/vue-carousel/api/-->
  <section class="partner mt-5">
    <div class="heading">
      <h4 class="heading-title">合作商戶恆常優惠</h4>
      <h5 class="heading-subtitle">
        成為我們的客戶，可在各大合作品牌享有會員待遇
      </h5>
    </div>
    <carousel
      :autoplay="true"
      :perPageCustom="[
        [0, 1],
        [768, 3],
        [1024, 4]
      ]"
    >
      <slide v-for="(item, index) in discounts" :key="`partner_${index}`">
        <b-card
          no-body
          class="partner-card"
          img-alt="Card Image"
          img-top
          :img-src="item.image_path"
        >
          <div class="block">{{ item.discount }}</div>
          <b-card-title class="mt-3">{{ item.company_name }}</b-card-title>
          <b-card-sub-title class="mb-2">{{ item.company_short_description }}</b-card-sub-title>
          <b-card-body v-if="item.discount_description != ''">
            <span class="discount_description">{{
              item.discount_description
            }}</span>
          </b-card-body>
        </b-card>
      </slide>
    </carousel>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BCardSubTitle,
} from "bootstrap-vue";
import { Carousel, Slide } from "vue-carousel";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    Carousel,
    Slide,
  },
  methods: {
    fetchDiscounts() {
      store
        .dispatch("home/fetchDiscounts", {
          sortBy: "sort_order",
          sortDesc: false,
        })
        .then((response) => {
          this.discounts = response.data.discounts;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  mounted() {
    this.fetchDiscounts();
  },
  data() {
    return {
      discounts:[],
      partnerContents: [
        {
          index: "1",
          company: "哥哥添飯・妹妹泡茶",
          discount: "送QQ波",
          category: "台式飲品及食品",
          discount_description: "買滿$50送多一個印花，買滿$200我地送多一客QQ波",
        },
        {
          index: "2",
          company: "Happy Milk",
          discount: "9折",
          category: "兒童服裝",
          discount_description: "",
        },
        {
          index: "3",
          company: "泰屋",
          discount: "9折",
          category: "泰式餐廳",
          discount_description: "",
        },
        {
          index: "4",
          company: "麥文記麵家",
          discount: "送飲品",
          category: "正宗雲吞麵",
          discount_description: "疫情期間開放時間或者有所變動",
        },
        {
          index: "5",
          company: "廣東名門",
          discount: "9折",
          category: "廣式荼樓",
          discount_description: "",
        },
        {
          index: "6",
          company: "Flourishes",
          discount: "送半打蛋糕",
          category: "蛋糕甜品",
          discount_description:
            "購買任何蛋糕甜品滿$500以上，即送馬卡龍禮盒(6個)價值$138",
        },
        {
          index: "7",
          company: "Trailblazer",
          discount: "85折",
          category: "行山用品",
          discount_description: "",
        },
        {
          index: "8",
          company: "聯蜂科技",
          discount: "9折",
          category: "POS系統服務",
          discount_description:
            "申請使用BeeOS POS系統可享9折優惠，原價$6800/年，優惠價$6120/年",
        },
        {
          index: "9",
          company: "Oriole",
          discount: "9折",
          category: "凍肉食品",
          discount_description: "",
        },
        {
          index: "10",
          company: "Model 1",
          discount: "9折",
          category: "模型精品",
          discount_description: "",
        },
      ],
    };
  },
};


</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.carousel {
  display: flex;
  align-items: stretch;
}
.partner-card {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  height: 95%;
}

.partner-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.block {
  min-width: 200%;
  height: 50px;
  text-align: center;
  padding: 0 5px;
  position: absolute;
  left: -90%;
  top: 5%;
  zoom: 0.5;
  font-size: 2.5em;
  transform: rotate(-42deg) scale(0.8);
  background: $secondary;
  color: #ffffff;
  transition: all 0.5s ease;
}

.card-title {
  color: $text-color;
}

.card-img-top {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
@media only screen and (max-width: 600px) {

}
</style>
