<template>
  <div>
    <b-modal
      id="modal-login"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
      title="登入"
      title-class="font-weight-bolder"
      body-class="common_modal "
      hide-footer
      centered
      size="md"
    >
      <!-- form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="auth-form mt-2" @submit.prevent>
          <!-- name -->
          <b-form-group label="電郵地址" label-for="login-name">
            <b-form-input
              id="login-name"
              v-model="userName"
              name="login-name"
              placeholder="輸入電郵地址"
            />
            <small v-if="!userName" class="text-danger">請輸入電郵地址</small>
          </b-form-group>

          <!--  password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="login-password">密碼</label>
            </div>

            <b-input-group
              class="input-group-merge"
              :class="!password ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="輸入密碼"
              />
              <b-input-group-append is-text>
                <component
                  class="cursor-pointer"
                  :is="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                ></component>
              </b-input-group-append>
            </b-input-group>
            <small v-if="!password" class="text-danger">請輸入密碼</small>
            <!-- </validation-provider> -->

            <a class="float-right cursor-pointer" @click="forgot">
              <small>忘記密碼?</small>
            </a>
          </b-form-group>
          <b-button
            class="mb-3 btn-login"
            type="submit"
            variant="primary"
            block
            @click="validationForm"
          >
            立即登入
          </b-button>
        </b-form>
      </b-overlay>
      <!-- </validation-observer> -->
    </b-modal>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
// import ToastificationContent from "@/libs/toastification/ToastificationContent";

import {
  BButton,
  BModal,
  BLink,
  VBModal,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    BButton,
    BModal,
    VBModal,
    BFormCheckbox,
    BLink,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    // vSelect,
    BCard,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      password: "",
      userName: "",
      loading: false,
      passwordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    forgot() {
      this.$bvModal.show("modal-forgot-password");
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    hide() {
      this.$bvModal.hide("modal-login");
      //refresh page
      this.$router.go();
    },
    validationForm() {
      if (this.userName && this.password) {
        this.login();
      } else {
      }
    },
    fetchCustomer(token) {
      // init run once
      this.$store
        .dispatch("fetchCustomer", token)
        .then((res) => {
          this.isInit = false;
          this.$store.commit("updateUserData", res.data.customer);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    login() {
      this.loading = true;
      this.$store
        .dispatch("login", {
          email: this.userName,
          password: this.password,
        })
        .then((response) => {
          this.loading = false;
          const { userData } = response.data;
          localStorage.clear();
          localStorage.setItem("accessToken", response.data.access_token);
         
          this.fetchCustomer(response.data.access_token)
          this.userName = "";
          this.password = "";

          this.$swal({
            text: "你已經成功登入！",
            icon: "success",
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          // this.$toast({
          //   position: "top-right",
          //   component: ToastificationContent,
          //   props: {
          //     title: `歡迎 ${userData.name}`,
          //     icon: "CoffeeIcon",
          //     variant: "success",
          //     text: `您已成功登錄!`,
          //   },
          // });
          setTimeout(() => {
            this.hide();
          }, 1000);
          // this.hide();
        })
        .catch((error) => {
          this.$swal({
            text:
              (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) ||
              "用戶名或密碼不正確!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.loading = false;
        });
    },
  },
  props: {},
};
</script>

<style lang="scss" scoped>
</style>

