<template>
  <div id="promotions" class="mt-3 col-12 text-center px-0 py-4 row no-gutters">
    <div class="heading">
      <h4 class="heading-title">最新消息</h4>
    </div>

    <!--<div class="offset-1">最新消息</div>-->
    <div class="col-12 row justify-content-center no-gutters d-md-flex">
      <div class="col-12 text-center">
        <el-carousel height="300px" style="padding: 10px">
          <el-carousel-item
            v-for="banner in banners"
            :key="'new' + banner.image_path"
          >
            <img
              class="d-inline-block h-100 lazyload"
              style="width: 100%; height: 100%"
              :src="banner.image_path"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <img
      class="d-none d-md-flex promotion-bg lazyload"
      src="@/assets/icon/superman-02.webp"
      alt="大昌超人"
    />
    <img
      class="d-none d-md-flex promotion-bg2 lazyload"
      src="@/assets/icon/supperman-03.webp"
      alt="大昌超人2"
    />
    <div class="offset-3 col-6 text-center mt-5 d-none d-md-block" v-if="false">
      <!-- sfpromotion language by Walter on 26 May 2020 -->
      <router-link to="/">
        <!-- <a href="/sfpromotions/"> -->

        <!-- <a href="javascript:void(0)" onclick="togglePromotions('addition-promo', this)"> -->
        <img
          data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/zh_TW/show-more-button.svg"
          alt="更多 迷你倉優惠"
          class="lazyload"
        />
      </router-link>
    </div>
    <div class="offset-3 col-6 text-center mt-3 d-md-none" v-if="false">
      <!-- sfpromotion language by Walter on 26 May 2020 -->
      <a
        href="https://storefriendlytower.com/en/20th-anniversary-promotion"
        target="_blank"
        rel="noopener noreferrer"
      >
        <!-- <a href="/sfpromotions/"> -->

        <!-- <a href="javascript:void(0)" onclick="togglePromotions('addition-promo-m', this)"> -->
        <img
          data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/zh_TW/show-more-button.svg"
          alt="More Storage Promotions"
          class="lazyload"
        />
      </a>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  data() {
    return {
      banners: {},
    };
  },
  methods: {
    fetchBanner() {
      store
        .dispatch("home/getBanner", {
          banner_category_id: 2,
          sortBy: "sort_order",
          sortDesc: false,
        })
        .then((response) => {
          this.banners = response.data.banners;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  mounted() {
    this.fetchBanner();
  },
};
</script>

<style>
#promotions .promotion-bg,
#promotions .promotion-bg2 {
  position: absolute;
  right: 0;
  bottom: -20px;
  width: 15%;
  z-index: 999;
}

#promotions .promotion-bg {
  left: 0;
}

#promotions .el-carousel__container {
  height: 300px !important;
}
</style>
