<template>
  <div>
    <modal-exempt-statement></modal-exempt-statement>
    <b-modal
      id="modal-register"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
      title="註冊"
      title-class="font-weight-bolder"
      body-class="common_modal "
      hide-footer
      centered
      size="md"
    >
      <!-- form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="auth-form mt-2" @submit.prevent>
          <!-- name -->
          <b-form-group label="用戶名" label-for="login-name">
            <b-form-input
              id="login-name"
              v-model="registerData.name"
              name="login-name"
              placeholder="輸入用戶名"
            />
            <small v-if="!registerData.name" class="text-danger"
              >請輸入用戶名</small
            >
          </b-form-group>

          <b-form-group class="required">
            <label>電郵</label>

            <b-form-input
              id="forgot-password-email"
              v-model="registerData.email"
              name="forgot-password-email"
              placeholder="輸入電郵"
            />
            <small v-if="!registerData.email" class="text-danger"
              >請輸入電郵地址</small
            >
            <small
              v-if="registerData.email && !emailCheck.test(registerData.email)"
              class="text-danger"
              >電郵地址格式錯誤</small
            >
          </b-form-group>

          <!--  password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="login-password">密碼</label>
            </div>

            <b-input-group
              class="input-group-merge"
              :class="!registerData.password ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="registerData.password"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="輸入密碼"
              />
              <b-input-group-append is-text>
                <component
                  class="cursor-pointer"
                  :is="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                ></component>
              </b-input-group-append>
            </b-input-group>
            <small v-if="!registerData.password" class="text-danger"
              >請輸入密碼</small
            >
          </b-form-group>

          <!-- component password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="login-password">確認密碼</label>
            </div>

            <b-input-group
              class="input-group-merge"
              :class="!registerData.c_password ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="registerData.c_password"
                class="form-control-merge"
                :type="c_passwordFieldType"
                name="login-password"
                placeholder="輸入確認密碼"
              />
              <b-input-group-append is-text>
                <component
                  class="cursor-pointer"
                  :is="c_passwordToggleIcon"
                  @click="c_togglePasswordVisibility"
                ></component>
              </b-input-group-append>
            </b-input-group>
            <small v-if="!registerData.c_password" class="text-danger"
              >請輸入確認密碼</small
            >
            <small
              v-if="
                registerData.c_password &&
                registerData.password !== registerData.c_password
              "
              class="text-danger"
              >新密碼與確認密碼不相同</small
            >
          </b-form-group>

          <!-- referral code -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="referral-code">推薦碼</label>
            </div>
            <b-form-input
              id="referral-code"
              v-model="registerData.referralCode"
              name="referral-code"
              placeholder="輸入推薦碼"
            />
          </b-form-group>

          <div class="d-flex mt-2 mb-1">
            <div class="d-inline-flex align-items-center">
              <b-form-checkbox
                v-model="is_readed"
                id="exempt"
                class="mr-0"
                inline
              />
              <label class="mb-0" for="exempt" style="white-space: nowrap"
                >剔選即表示你同意我們的<a
                  class="link-green"
                  @click="$bvModal.show(`modal-exempt-statement`)"
                  href="javascript:void(0)"
                  >免責聲明</a
                >。</label
              >
            </div>
          </div>
          <small v-show="!is_readed" class="text-danger"
            >請同意我們的免責聲明</small
          >

          <b-button
            class="mb-3 btn-login"
            type="submit"
            variant="primary"
            block
            @click="validationForm"
          >
            立即注冊
          </b-button>
        </b-form>
      </b-overlay>
      <!-- </validation-observer> -->
    </b-modal>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
import axiosIns from "@/libs/axios";
import {
  BButton,
  BModal,
  BLink,
  VBModal,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ModalExemptStatement from "./ModalExemptStatement.vue";
import {extractStrings} from "../../../libs/helper"
export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    BButton,
    BModal,
    VBModal,
    BFormCheckbox,
    BLink,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    ModalExemptStatement,
    // vSelect,
    BCard,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      registerData: {},
      is_readed:false,
      emailCheck: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      loading: false,
      passwordFieldType: "password",
      c_passwordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    c_passwordToggleIcon() {
      return this.c_passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    c_togglePasswordVisibility() {
      this.c_passwordFieldType =
        this.c_passwordFieldType === "password" ? "text" : "password";
    },
    hide() {
      this.$bvModal.hide("modal-register");
    },
    validationForm() {
      if (
        this.registerData.name &&
        this.registerData.email &&
        this.registerData.password &&
        this.registerData.c_password &&
        this.emailCheck.test(this.registerData.email) &&
        this.registerData.password === this.registerData.c_password &&
        this.is_readed
      ) {
        this.register();
      } else {
      }
    },
    register() {
      this.loading = true;
      axiosIns
        .post("register", this.registerData)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.hide();
          this.$bvModal.show("modal-login");
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            text: extractStrings(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
  props: {},
};
</script>

<style lang="scss" scoped>
</style>

