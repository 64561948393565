<template>
  <div id="video_section" class="mt-3">
    <div class="heading">
      <h4 class="heading-title">Video</h4>
    </div>

    <div class="d-flex overflow-auto align-items-center">
      <div v-for="item in youtubes" :key="item.url" class="mx-2">
        <div class="">
          <div v-html="item.url"></div>
        </div>
      </div>
    </div>
    <!--</carousel> -->
  </div>
</template>
<script>
import { BButton, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      youtubes:[]
    };
  },
  computed: {
  },
  methods:{
    getVideos(){
      store.dispatch("home/getVideos", {sortBy:'sort_order', sortDesc:false})
      .then(response => {
        this.youtubes = response.data.videos;
      })
      .catch(error => {
        console.log({error})
      })
    },
    generateRandomKey(item) {
      return `${item.id}_${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  mounted(){
    this.getVideos()
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
</style>
