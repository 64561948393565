<template>
  <b-modal
    id="modal-exempt-statement"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    title="免責聲明"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <div class="exempt" v-if="pageData">
      <div v-html="pageData.content" class="text-left about-content m-4"></div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
  
<script>
import Ripple from "vue-ripple-directive";
// import vSelect from "vue-select";
// import { useToast } from "vue-toastification/composition";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref, watch, computed } from "@vue/composition-api";
// import axiosIns from "@/libs/axios";
// import ability from "@/libs/acl/ability";
import {
  BModal,
  BButton,
  BFormInput,
  BTable,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BTable,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageData: null,
    };
  },
  methods: {
    fetchPage() {
      this.$store
        .dispatch("fetchPage", { slug: "statement" })
        .then((response) => {
          this.pageData = response.data.page;
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  mounted() {
    this.fetchPage();
  },
  watch: {
    $route(to, from) {
      this.fetchPage();
    },
  },
};
</script>
  
  
  <style lang="scss">
#modal-exempt-statement {
  p {
    margin-bottom: 1.3rem;
  }
}
</style>