import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/about/About.vue')
  },
  {
    path: '/warehouses?id=:id',
    name: 'warehouse',
    component: () => import('@/views/warehouse/Warehouse.vue')
  },
  {
    path: '/warehouses',
    name: 'warehouse',
    component: () => import('@/views/warehouse/Warehouse.vue')
  },
  {
    path: '/warehousedetail?warehouse_id=:warehouse_id',
    name: 'warehouse-detail',
    component: () => import('@/views/warehouse/WarehouseDetail.vue')
  },
  {
    path: '/warehousedetail',
    name: 'warehouse-detail',
    component: () => import('@/views/warehouse/WarehouseDetail.vue')
  },
  // {
  //   path: '/checkout?store_id=:store_id&warehouse_id=:warehouse_id',
  //   name: 'checkout',
  //   component: () => import('@/views/warehouse/WarehouseBooking.vue')
  // },

  {
    path: '/personal-center',
    name: 'personal-center',
    component: () => import('@/views/personal/Center.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/invoice/invoice-list/invoiceList.vue')
  },
  {
    path: '/payment-records?customer_id=:customer_id',
    name: 'payment-records',
    component: () => import('@/views/payment/Records-table.vue')
  },
  {
    path: '/payment-records',
    name: 'payment-records',
    component: () => import('@/views/payment/Records-table.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/pages/page.vue')
  },
  {
    path: '/term',
    name: 'term',
    component: () => import('@/views/pages/page.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/warehouse/WarehouseBooking.vue')
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('@/views/warehouse/WarehouseAppointment.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/Contact.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router