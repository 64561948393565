<template>
  <div>
    <b-modal
      id="modal-forgot-password"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
      title="忘記密碼？"
      title-class="font-weight-bolder"
      body-class="common_modal "
      hide-footer
      centered
      @close="resetData = {}"
      @show="resetData = {}"
      size="md"
    >
      <!-- Forgot password-->

          <b-card-text class="mt-1 mb-2">
            請輸入您的電郵並按下發送驗證碼按鈕，我們將向您發送驗證碼
          </b-card-text>

          <!-- form -->
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-form
                class="auth-form mt-3"
                @submit.prevent="validationForm"
              >
                <b-form-group class="required">
                  <label>電郵</label>

                  <b-input-group>
                    <b-form-input
                      id="forgot-password-email"
                      v-model="resetData.email"
                      name="forgot-password-email"
                      placeholder="輸入電郵"
                    />
                    <b-input-group-append is-text>
                      <b-button
                        variant="primary"
                        class="vertify-btn"
                        @click="sendVertificationCode"
                      >
                        發送驗證碼
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="!resetData.email" class="text-danger"
                    >請輸入電郵地址</small
                  >
                  <small
                    v-if="resetData.email && !emailCheck.test(resetData.email)"
                    class="text-danger"
                    >電郵地址格式錯誤</small
                  >
                </b-form-group>

                <b-form-group class="required mt-1" label="新密碼：">
                  <b-form-input
                    id="password"
                    type="password"
                    placeholder="輸入新密碼"
                    v-model="resetData.password"
                  />
                  <small v-if="!resetData.password" class="text-danger">請輸入密碼</small>
                </b-form-group>

                <b-form-group class="required" label="確認密碼：">
                  <b-form-input
                    id="c_password"
                    type="password"
                    placeholder="輸入確認密碼"
                    v-model="resetData.c_password"
                  />
                  <small v-if="!resetData.c_password" class="text-danger">請輸入確認密碼</small>
                  <small
                    v-if="
                      resetData.c_password &&
                      resetData.password !== resetData.c_password
                    "
                    class="text-danger"
                    >新密碼與確認密碼不相同</small>
                </b-form-group>

                <b-form-group class="required" label="驗證碼：">
                  <b-form-input
                    id="vaildate"
                    placeholder="輸入驗證碼"
                    v-model="resetData.vertification_code"
                  />
                  <small
                    v-if="!resetData.vertification_code"
                    class="text-danger"
                    >請輸入驗證碼</small
                  >
                </b-form-group>
                <div>
                  <b-button type="submit" variant="primary" block class="">
                    提交
                  </b-button>
                </div>
              </b-form>
            </b-overlay>

          <p class="text-center mt-3">
            <a href="javascript:void(0)" @click="goLogin">返回登入頁</a>
          </p>
      <!-- /Forgot password-->
    </b-modal>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BButton,
  BModal,
  BLink,
  VBModal,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BFormCheckbox,
    BLink,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardBody,
    // vSelect,
    BCard,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      resetData: {},
      loading: false,
      emailCheck: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };
  },
  computed: {},
  methods: {
    hide() {
      this.$bvModal.hide("modal-forgot-password");
    },
    goLogin(){
      this.hide();
      this.$bvModal.show("modal-login");
    },
    validationForm() {
      if (this.resetData.email && this.resetData.password && this.resetData.password === this.resetData.c_password && this.resetData.vertification_code ) {
        this.resetPasswordForm();
      } else {
      }
    },
    resetPasswordForm() {
      this.loading = true;
      axiosIns
        .post("/resetpassword_with_code", this.resetData)
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          // this.$router.replace("/login");
          this.hide();
          this.$bvModal.show("modal-login");
          this.loading = false;

        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$swal({
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    sendVertificationCode() {
      this.loading = true;
      axiosIns
        .post("/sendVertificationCode", { email: this.resetData.email })
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style lang="scss">
#forgot-password-email {
  border-radius: 0.357rem 0 0 0.357rem;
}

.vertify-btn{
  font-size: 0.8rem!important;
    padding: 0.1rem 0.5rem!important;
}
.modal .modal-body a.close-btn{
  float:right;
  color: black!important;
}

.input-group-text {
  padding: 0rem 0.5rem;
}
</style>
