var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(false)?_c('div',{staticClass:"row d-md-none accordion",attrs:{"id":"site_map_mobile"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),_c('div',{staticClass:"row d-none d-md-block",attrs:{"id":"site_map_desktop"}},[_c('div',{staticClass:"col-12",attrs:{"id":"top_sep"}}),(false)?_c('div',{staticClass:"row offset-2 py-5 col-10",attrs:{"id":"serv_intro"}},[_c('span',{staticClass:"col-2 px-0 py-3",staticStyle:{"color":"white"}},[_vm._v("服務介紹")]),_vm._m(5)]):_vm._e(),_c('div',{staticClass:"row offset-2 py-3 col-10 mt-3",attrs:{"id":"site_map_mobile_detail"}},[_vm._l((_vm.footer_items),function(header,index){return _c('div',{key:index,staticClass:"col-2 p-0"},[_c('span',[_vm._v(_vm._s(header.name))]),_vm._l((header.children),function(item,index){return _c('router-link',{key:`footer_item_${index}`,attrs:{"to":item.to}},[_vm._v(_vm._s(item.name))])})],2)}),_c('div',{staticClass:"col-3 p-0"},[_c('span',{staticClass:"mb-2"},[_vm._v("關注我們")]),(_vm.$store.state.settings && _vm.$store.state.settings.length > 0)?_c('a',{staticClass:"d-inline-block ml-1",attrs:{"href":_vm.$store.state.settings[_vm.findInvoiceIndex('facebook')].value,"target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg","alt":"Storage Facebook"}})]):_vm._e(),(_vm.$store.state.settings && _vm.$store.state.settings.length > 0)?_c('a',{staticClass:"d-inline-block ml-2",attrs:{"href":`https://wa.me/${_vm.$store.state.settings[
            _vm.findInvoiceIndex('tel')
          ].value
            .split(' ')
            .join('')}`,"target":"_blank"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg","alt":"全港最多人選用迷你倉 whatsapp"}})]):_vm._e(),(_vm.$store.state.settings && _vm.$store.state.settings.length > 0)?_c('a',{staticClass:"d-inline-block ml-2",attrs:{"href":`https://wa.me/${_vm.$store.state.settings[
            _vm.findInvoiceIndex('tel2')
          ].value
            .split(' ')
            .join('')}`,"target":"_blank"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg","alt":"全港最多人選用迷你倉 whatsapp2"}})]):_vm._e(),_c('br'),_c('br')])],2)]),_c('footer',[_c('div',{staticClass:"row text-center py-4",staticStyle:{"margin-left":"0","margin-right":"0"},attrs:{"id":"copyright"}},[_c('div',{staticClass:"col"},[_vm._m(6),_c('router-link',{attrs:{"to":"/term"}},[_vm._v("條款及細則")]),_vm._v(" | "),_c('router-link',{attrs:{"to":"/privacy"}},[_vm._v("個人資料及私隱聲明")]),_c('span',{staticClass:"mt-2"},[_vm._v("大昌集團 © All Right Reserved "+_vm._s(new Date().getFullYear()))])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"card-header p-0",attrs:{"id":"site_map_about_sf"}},[_c('a',{staticClass:"card-link d-block w-100 py-2",attrs:{"data-toggle":"collapse","href":"#site_map_about_sf_cont"}},[_c('span',{staticClass:"plus-sign ml-3"},[_vm._v("＋")]),_c('span',{staticClass:"ml-2"},[_vm._v("關於儲存易")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"site_map_about_sf_cont","aria-labelledby":"site_map_about_sf","data-parent":"#site_map_mobile"}},[_c('div',{staticClass:"card-body py-0 px-5"},[_c('a',{staticClass:"py-2",attrs:{"href":"aboutus.html"}},[_vm._v("公司簡介")]),_c('a',{staticClass:"py-2",attrs:{"href":"news/media.html"}},[_vm._v("傳媒報導")]),_c('a',{staticClass:"py-2",attrs:{"href":"http://www.bossible.com/","target":"_blank","rel":"noopener noreferrer nofollow"}},[_vm._v("加盟我們")]),_c('a',{staticClass:"py-2",attrs:{"href":"jobopportunities.html"}},[_vm._v("工作機會")]),_c('a',{staticClass:"py-2",attrs:{"href":"http://blog.store-friendly.com/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" 儲存易網誌")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"card-header p-0",attrs:{"id":"site_map_service"}},[_c('a',{staticClass:"card-link d-block w-100 py-2",attrs:{"data-toggle":"collapse","href":"#site_map_service_cont"}},[_c('span',{staticClass:"plus-sign ml-3"},[_vm._v("＋")]),_c('span',{staticClass:"ml-2"},[_vm._v("服務與產品")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"site_map_service_cont","aria-labelledby":"site_map_service","data-parent":"#site_map_mobile"}},[_c('div',{staticClass:"card-body py-0 px-5"},[_c('a',{staticClass:"py-2",attrs:{"href":"sfpromotions/index.html"}},[_vm._v("最新優惠")]),_c('a',{staticClass:"py-2",attrs:{"href":"branches.html"}},[_vm._v("迷你倉")]),_c('a',{staticClass:"py-2",attrs:{"href":"branches/TsuenWanwinecellar3/F.html","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("紅酒倉")]),_c('a',{staticClass:"py-2",attrs:{"href":"http://www.storefriendlyon.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("StoreFriendly on Demand")]),_c('a',{staticClass:"py-2",attrs:{"href":"services/storefriendlymover.html"}},[_vm._v("Storefriendly搬運")]),_c('a',{staticClass:"py-2",attrs:{"href":"branches/StorefriendlyGo.html"}},[_vm._v("StoreFriendlyGO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"card-header p-0",attrs:{"id":"site_map_know_sf"}},[_c('a',{staticClass:"card-link d-block w-100 py-2",attrs:{"data-toggle":"collapse","href":"#site_map_know_sf_cont"}},[_c('span',{staticClass:"plus-sign ml-3"},[_vm._v("＋")]),_c('span',{staticClass:"ml-2"},[_vm._v("了解迷你倉")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"site_map_know_sf_cont","aria-labelledby":"site_map_know_sf","data-parent":"#site_map_mobile"}},[_c('div',{staticClass:"card-body py-0 px-5"},[_c('a',{staticClass:"py-2",attrs:{"href":"storagecontact.html"}},[_vm._v("分店位置")]),_c('a',{staticClass:"py-2",attrs:{"href":"onlinebooking.html"}},[_vm._v("預約租倉")]),_c('a',{staticClass:"py-2",attrs:{"href":"https://my.matterport.com/show/?m=99rt4uifVQe","target":"_blank","rel":"noopener noreferrer nofollow"}},[_vm._v("360網上睇倉")]),_c('a',{staticClass:"py-2",attrs:{"href":"faq.html"}},[_vm._v("常見問題")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"card-header p-0",attrs:{"id":"site_map_cs"}},[_c('a',{staticClass:"card-link d-block w-100 py-2",attrs:{"data-toggle":"collapse","href":"#site_map_cs_cont"}},[_c('span',{staticClass:"plus-sign ml-3"},[_vm._v("＋")]),_c('span',{staticClass:"ml-2"},[_vm._v("客戶服務")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"site_map_cs_cont","aria-labelledby":"site_map_cs","data-parent":"#site_map_mobile"}},[_c('div',{staticClass:"card-body py-0 px-5"},[_c('a',{staticClass:"py-2",attrs:{"href":"https://cs.store-friendly.com","target":"_blank","rel":"noopener noreferrer nofollow"}},[_vm._v("網上客戶帳戶")]),_c('a',{staticClass:"py-2",attrs:{"href":"http://219.90.118.194/discuz/index.php","target":"_blank","rel":"noopener noreferrer nofollow"}},[_vm._v("特許經營帳戶")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"card-header p-0",attrs:{"id":"site_map_follow_us"}},[_c('a',{staticClass:"card-link d-block w-100 py-2",attrs:{"data-toggle":"collapse","href":"#site_map_follow_us_cont"}},[_c('span',{staticClass:"plus-sign ml-3"},[_vm._v("＋")]),_c('span',{staticClass:"ml-2"},[_vm._v("關注我們")])])]),_c('div',{staticClass:"collapse",attrs:{"id":"site_map_follow_us_cont","aria-labelledby":"site_map_follow_us","data-parent":"#site_map_mobile"}},[_c('div',{staticClass:"card-body py-2 px-5"},[_c('a',{staticClass:"d-inline-block ml-1",attrs:{"href":"https://www.facebook.com/dahcheong","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg","alt":"儲存易迷你倉 facebook"}})]),_c('a',{staticClass:"d-inline-block ml-2",attrs:{"href":"javascript:void(0)"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg","alt":"儲存易 whatsapp"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-9 row justify-content-around"},[_c('div',{staticClass:"Serviceicon"},[_c('a',{attrs:{"href":"index.html"}},[_c('img',{staticClass:"lazyload",attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png","alt":"儲存易迷你倉"}})])]),_c('div',{staticClass:"Serviceicon"},[_c('a',{attrs:{"href":"http://www.storefriendlycellar.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"lazyload",staticStyle:{"margin-left":"-183px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png","alt":"儲存易紅酒倉"}})])]),_c('div',{staticClass:"Serviceicon"},[_c('a',{attrs:{"href":"branches/StorefriendlyGo.html"}},[_c('img',{staticClass:"lazyload",staticStyle:{"margin-left":"-366px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png","alt":"儲存易迷你倉"}})])]),_c('div',{staticClass:"Serviceicon"},[_c('a',{attrs:{"href":"http://www.storefriendlyon.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"lazyload",staticStyle:{"margin-left":"-549px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png","alt":"Storefriendly On Demand"}})])]),_c('div',{staticClass:"Serviceicon"},[_c('a',{attrs:{"href":"branches/StorefriendlyHobbies.html"}},[_c('img',{staticClass:"lazyload",staticStyle:{"margin-left":"-732px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png","alt":"Storefriendly Hobbies"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-md-none"},[_c('a',{staticClass:"d-inline-block",staticStyle:{"margin-bottom":"10px"},attrs:{"href":"https://apps.apple.com/hk/app/%E5%84%B2%E5%AD%98%E6%98%93%E8%BF%B7%E4%BD%A0%E5%80%89-storefriendly/id1474815814"}},[_c('img',{staticClass:"lazyload",staticStyle:{"height":"30px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/ios_logo.svg","alt":"StoreFriendly iOS Application"}})]),_c('a',{staticClass:"d-inline-block",staticStyle:{"margin-bottom":"10px"},attrs:{"href":"https://play.google.com/store/apps/details?id=com.storefriendly.mobileapp2019"}},[_c('img',{staticClass:"lazyload",staticStyle:{"height":"30px"},attrs:{"data-src":"https://d28oxtqrsher4l.cloudfront.net/public/img2/android_logo.svg","alt":"StoreFriendly Android Application"}})])])
}]

export { render, staticRenderFns }