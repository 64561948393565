<template>
  <div id="app" class="h-100">
    <layout-vertical>
      <router-view :key="$route.fullPath"/>
    </layout-vertical>
  </div>
</template>

<script>
import store from '@/store'
import LayoutVertical from "@/layouts/vertical/LayoutVertical.vue"
import storeModule from "@/store/index"


export default{
  components:{
    LayoutVertical
  },
  methods:{
    fetchSetting() {
      store
        .dispatch("fetchSetting")
        .then((response) => {
            store.state.settings = response.data.settings
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  mounted() {
    this.fetchSetting();
  },
  setup(){
    const STORE_MODULE_NAME = 'info'
    store.registerModule(STORE_MODULE_NAME, storeModule)

  }
}


</script>



<style lang="scss">
@import "@/assets/scss/_variables.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.background-text{
    background: $text-color !important;
    border-color: $text-color  !important;
}

.background-primary{
    background: $primary !important;
    border-color: $primary  !important;
}

.background-secondary{
    background: $secondary !important;
    border-color: $secondary  !important;
}

.color-primary{
  color: $primary !important;
}

.color-text{
  color: $text-color !important;
}

.color-secondary{
  color: $secondary !important;
}



</style>
