<template>
  <div>
    <div id="site_map_mobile" class="row d-md-none accordion" v-if="false">
      <div class="w-100">
        <div id="site_map_about_sf" class="card-header p-0">
          <a
            class="card-link d-block w-100 py-2"
            data-toggle="collapse"
            href="#site_map_about_sf_cont"
          >
            <span class="plus-sign ml-3">＋</span>
            <span class="ml-2">關於儲存易</span>
          </a>
        </div>
        <div
          id="site_map_about_sf_cont"
          class="collapse"
          aria-labelledby="site_map_about_sf"
          data-parent="#site_map_mobile"
        >
          <div class="card-body py-0 px-5">
            <!-- sitemap language by Walter on 26 May 2020 -->
            <a class="py-2" href="aboutus.html">公司簡介</a>
            <a class="py-2" href="news/media.html">傳媒報導</a>
            <a
              class="py-2"
              href="http://www.bossible.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >加盟我們</a
            >
            <a class="py-2" href="jobopportunities.html">工作機會</a>
            <a
              class="py-2"
              href="http://blog.store-friendly.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              儲存易網誌</a
            >
          </div>
        </div>
      </div>

      <div class="w-100">
        <div id="site_map_service" class="card-header p-0">
          <a
            class="card-link d-block w-100 py-2"
            data-toggle="collapse"
            href="#site_map_service_cont"
          >
            <span class="plus-sign ml-3">＋</span>
            <span class="ml-2">服務與產品</span>
          </a>
        </div>
        <div
          id="site_map_service_cont"
          class="collapse"
          aria-labelledby="site_map_service"
          data-parent="#site_map_mobile"
        >
          <div class="card-body py-0 px-5">
            <!-- sitemap language by Walter on 26 May 2020 -->
            <a class="py-2" href="sfpromotions/index.html">最新優惠</a>
            <a class="py-2" href="branches.html">迷你倉</a>
            <a
              class="py-2"
              href="branches/TsuenWanwinecellar3/F.html"
              target="_blank"
              rel="noopener noreferrer"
              >紅酒倉</a
            >
            <a
              class="py-2"
              href="http://www.storefriendlyon.com"
              target="_blank"
              rel="noopener noreferrer"
              >StoreFriendly on Demand</a
            >
            <a class="py-2" href="services/storefriendlymover.html"
              >Storefriendly搬運</a
            >
            <a class="py-2" href="branches/StorefriendlyGo.html"
              >StoreFriendlyGO</a
            >
          </div>
        </div>
      </div>

      <div class="w-100">
        <div id="site_map_know_sf" class="card-header p-0">
          <a
            class="card-link d-block w-100 py-2"
            data-toggle="collapse"
            href="#site_map_know_sf_cont"
          >
            <span class="plus-sign ml-3">＋</span>
            <span class="ml-2">了解迷你倉</span>
          </a>
        </div>
        <div
          id="site_map_know_sf_cont"
          class="collapse"
          aria-labelledby="site_map_know_sf"
          data-parent="#site_map_mobile"
        >
          <div class="card-body py-0 px-5">
            <!-- sitemap language by Walter on 26 May 2020 -->
            <a class="py-2" href="storagecontact.html">分店位置</a>
            <a class="py-2" href="onlinebooking.html">預約租倉</a>
            <a
              class="py-2"
              href="https://my.matterport.com/show/?m=99rt4uifVQe"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >360網上睇倉</a
            >
            <a class="py-2" href="faq.html">常見問題</a>
          </div>
        </div>
      </div>

      <div class="w-100">
        <div id="site_map_cs" class="card-header p-0">
          <a
            class="card-link d-block w-100 py-2"
            data-toggle="collapse"
            href="#site_map_cs_cont"
          >
            <span class="plus-sign ml-3">＋</span>
            <span class="ml-2">客戶服務</span>
          </a>
        </div>
        <div
          id="site_map_cs_cont"
          class="collapse"
          aria-labelledby="site_map_cs"
          data-parent="#site_map_mobile"
        >
          <div class="card-body py-0 px-5">
            <a
              class="py-2"
              href="https://cs.store-friendly.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >網上客戶帳戶</a
            >
            <a
              class="py-2"
              href="http://219.90.118.194/discuz/index.php"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >特許經營帳戶</a
            >
          </div>
        </div>
      </div>

      <div class="w-100">
        <div id="site_map_follow_us" class="card-header p-0">
          <a
            class="card-link d-block w-100 py-2"
            data-toggle="collapse"
            href="#site_map_follow_us_cont"
          >
            <span class="plus-sign ml-3">＋</span>
            <span class="ml-2">關注我們</span>
          </a>
        </div>
        <div
          id="site_map_follow_us_cont"
          class="collapse"
          aria-labelledby="site_map_follow_us"
          data-parent="#site_map_mobile"
        >
          <div class="card-body py-2 px-5">
            <a
              href="https://www.facebook.com/dahcheong"
              target="_blank"
              class="d-inline-block ml-1"
              rel="noopener noreferrer"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg"
                alt="儲存易迷你倉 facebook"
                class="lazyload"
            /></a>
            <a
              href="javascript:void(0)"
             
              class="d-inline-block ml-2"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg"
                alt="儲存易 whatsapp"
                class="lazyload"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="site_map_desktop" class="row d-none d-md-block">
      <div id="top_sep" class="col-12"></div>
      <div id="serv_intro" class="row offset-2 py-5 col-10" v-if="false">
        <span class="col-2 px-0 py-3" style="color: white">服務介紹</span>
        <div class="col-9 row justify-content-around">
          <!-- css image sprites by Walter on 23 Dec 2019 -->

          <div class="Serviceicon">
            <a href="index.html">
              <img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png"
                alt="儲存易迷你倉"
                class="lazyload"
              />
            </a>
          </div>
          <!--<a href="/">
                            <img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-storefriendly.png" alt="儲存易迷你倉" class="lazyload"/>
                        </a>-->

          <div class="Serviceicon">
            <a
              href="http://www.storefriendlycellar.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png"
                style="margin-left: -183px"
                alt="儲存易紅酒倉"
                class="lazyload"
              />
            </a>
          </div>
          <!--<a href="http://www.storefriendlycellar.com" target="_blank" rel="noopener noreferrer">
                            <img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-wine-cellar.png" alt="儲存易紅酒倉" class="lazyload"/>
                        </a>-->

          <div class="Serviceicon">
            <a href="branches/StorefriendlyGo.html">
              <img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png"
                style="margin-left: -366px"
                alt="儲存易迷你倉"
                class="lazyload"
              />
              <!--<img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-storefriendly.png" alt="儲存易迷你倉" class="lazyload"/>-->
            </a>
          </div>
          <!--<a href="/branches/StorefriendlyGo">
                            <img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-storefriendly-go.png" alt="Storefriendly Go" class="lazyload"/>
                        </a>-->

          <div class="Serviceicon">
            <a
              href="http://www.storefriendlyon.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png"
                style="margin-left: -549px"
                alt="Storefriendly On Demand"
                class="lazyload"
              />
            </a>
          </div>
          <!--<a href="http://www.storefriendlyon.com" target="_blank" rel="noopener noreferrer">
                            <img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-storefriendly-ondemand.png" alt="Storefriendly On Demand" class="lazyload"/>
                        </a>-->

          <div class="Serviceicon">
            <a href="branches/StorefriendlyHobbies.html">
              <img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/services3.png"
                style="margin-left: -732px"
                alt="Storefriendly Hobbies"
                class="lazyload"
              />
            </a>
          </div>
          <!--<a href="/branches/StorefriendlyHobbies">
                            <img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/serv-intro-storefriendly-hobbies.png" alt="Storefriendly Hobbies" class="lazyload"/>
                        </a>-->
        </div>
      </div>

      <div id="site_map_mobile_detail" class="row offset-2 py-3 col-10 mt-3">
        <div
          class="col-2 p-0"
          v-for="(header, index) in footer_items"
          :key="index"
        >
          <span>{{ header.name }}</span>
          <router-link
            :to="item.to"
            v-for="(item, index) in header.children"
            :key="`footer_item_${index}`"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="col-3 p-0">
          <span class="mb-2">關注我們</span>
          <!-- test by Walter on SVG sprites on 08 Jan 2020 -->
          <a
            v-if="$store.state.settings && $store.state.settings.length > 0"
            :href="$store.state.settings[findInvoiceIndex('facebook')].value"
            target="_blank"
            class="d-inline-block ml-1"
            rel="noopener noreferrer"
            ><img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/facebook.svg"
              alt="Storage Facebook"
              class="lazyload"
          /></a>
          <!-- <a href="https://www.instagram.com/storefriendlyselfstorage/" target="_blank" class="d-inline-block ml-2" rel="noopener noreferrer nofollow"><img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/ig.svg" alt="No.1 Self Storage Brand Instagram" class="lazyload" /></a>
                        <a href="https://mp.weixin.qq.com/s/uXV0eeGBuh_uw3IT3n3aOw" target="_blank" class="d-inline-block ml-2" rel="noopener noreferrer nofollow"><img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/wechat.svg" alt="儲存易 24小時 wechat" class="lazyload" /></a>
                        <a href="https://www.youtube.com/user/storefriendlyphoto1/" target="_blank" class="d-inline-block ml-2" rel="noopener noreferrer"><img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/youtube.svg" alt="Mini Storage youtube" class="lazyload" /></a> -->
          <a
            v-if="$store.state.settings && $store.state.settings.length > 0"
            :href="`https://wa.me/${$store.state.settings[
              findInvoiceIndex('tel')
            ].value
              .split(' ')
              .join('')}`"
            target="_blank"
           
            class="d-inline-block ml-2"
            ><img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg"
              alt="全港最多人選用迷你倉 whatsapp"
              class="lazyload"
          /></a>
          <a
            v-if="$store.state.settings && $store.state.settings.length > 0"
            :href="`https://wa.me/${$store.state.settings[
              findInvoiceIndex('tel2')
            ].value
              .split(' ')
              .join('')}`"
            target="_blank"
           
            class="d-inline-block ml-2"
            ><img
              data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/whatsapp.svg"
              alt="全港最多人選用迷你倉 whatsapp2"
              class="lazyload"
          /></a>
          <br /><br />
          <!-- <a href="https://apps.apple.com/hk/app/%E5%84%B2%E5%AD%98%E6%98%93%E8%BF%B7%E4%BD%A0%E5%80%89-storefriendly/id1474815814" class="d-inline-block"><img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/ios_logo.svg" alt="StoreFriendly iOS Application" class="lazyload" /></a> 
                        <a href="https://play.google.com/store/apps/details?id=com.storefriendly.mobileapp2019" class="d-inline-block"><img data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/android_logo.svg" alt="StoreFriendly Android Application" class="lazyload" /></a>                         -->
          <!--<span class="mb-2">Share with Others!</span>
                        <div class="sharethis-inline-share-buttons"></div> -->
        </div>
      </div>
    </div>
    <footer>
      <div
        id="copyright"
        class="row text-center py-4"
        style="margin-left: 0; margin-right: 0"
      >
        <div class="col">
          <div class="d-md-none">
            <a
              href="https://apps.apple.com/hk/app/%E5%84%B2%E5%AD%98%E6%98%93%E8%BF%B7%E4%BD%A0%E5%80%89-storefriendly/id1474815814"
              class="d-inline-block"
              style="margin-bottom: 10px"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/ios_logo.svg"
                style="height: 30px"
                alt="StoreFriendly iOS Application"
                class="lazyload"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.storefriendly.mobileapp2019"
              class="d-inline-block"
              style="margin-bottom: 10px"
              ><img
                data-src="https://d28oxtqrsher4l.cloudfront.net/public/img2/android_logo.svg"
                style="height: 30px"
                alt="StoreFriendly Android Application"
                class="lazyload"
            /></a>
          </div>
          <!-- <router-link to="/">加入我們</router-link> | -->
          <router-link to="/term">條款及細則</router-link> |
          <router-link to="/privacy">個人資料及私隱聲明</router-link>
          <span class="mt-2"
            >大昌集團 © All Right Reserved {{ new Date().getFullYear() }}</span
          >
          <!--<span class="mt-2">迷你倉, 自存倉, 儲存倉, Hong Kong Mini Storage, Self Storage, 貨倉, mini warehouse,</div>-->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      footer_items: [
        {
          name: "關於大昌",
          to: "/",
          children: [{ name: "公司簡介", to: "/about" }],
        },
        { name: "服務與產品", to: "/", children: [] },
        {
          name: "了解迷你倉",
          to: "/",
          children: [{ name: "聯絡我們", to: "/contact" }],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      findInvoiceIndex: "findInvoiceIndex",
    }),
  },
  methods: {
    warehouseLinks() {
    //   setTimeout(() => {
        this.footer_items[1].children =
          this.$store.state.warehouseCategories.map((category) => ({
            name: category.name,
            to: `/warehouses?id=${category.id}`,
          }));
    //   }, 1000);
    },
  },
  mounted() {
    this.$watch(
    () => this.$store.state.warehouseCategories,
    (newVal) => {
      if (newVal) {
        // 确保 warehouseCategories 已经加载完成
        this.warehouseLinks();
      }
    }
  );
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#site_map_desktop {
  background: $primary;
}

#copyright {
  background: $primary;
}

#top_sep {
  background-color: $secondary !important;
}

#site_map_desktop #site_map_mobile_detail a {
  color: white;
}

footer {
  border-bottom: 6px $secondary solid;
}

@media (max-width: 767px) {
  #site_map_mobile {
    background: $primary;
  }
}
</style>
