<template>
  <div class="home-banner">
    <el-carousel :interval="5000" direction="vertical" :autoplay="true" class="image-wrapper">
    <el-carousel-item v-for="banner in banners" :key="banner.image_path">
      <div style="width: 100%; height: auto;  display: flex; align-items: center; background: lightgrey">
        <img :src="banner.image_path" style="width: 100%; height:100%; object-fit:cover;"/>
      </div>
    </el-carousel-item>
  </el-carousel>
     <!-- <img src="@/assets/banner/banner.jpg" style="width: 100%; height:100%; object-fit:cover;"/> -->
  </div>
</template>
 
<script>
import store from "@/store";
export default{
  data(){
    return {
      banners: {},
    }
  },
  methods:{
     fetchBanner(){
      store.dispatch("home/getBanner", {banner_category_id: 1, sortBy:'sort_order', sortDesc:false})
      .then(response => {
        this.banners = response.data.banners;
      })
      .catch(error => {
        console.log({error})
      })
    }  
  },
  mounted(){
    this.fetchBanner()
  },
}
</script>


<style>
.home-banner .el-carousel__container{
  height:auto!important;
  aspect-ratio: 1900/1000;
}
</style>
