<template>
   <div class="row offset-1 col-10 mb-4 my-md-5 text-center">
        <div class="col-12 col-md-6 row no-gutters p-0">

            <div class="col p-0">
                <b-button style="background-color:white; border:none;"
                v-b-popover.hover.top="'亞洲迷你倉協會會員'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                >
                        <img src="@/assets/award/ssaa.webp" style="height:100px;" class="" alt="QMark" />    
                </b-button>
            </div>
            <div class="col p-0">
                <b-button style="background-color:white; border:none;"
                v-b-popover.hover.top="'附合最新消防安全標準'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                >
                        <img src="@/assets/award/fire.webp"  style="height:100px;" class="" alt="ISO9001" />    
                </b-button>   
            </div>
         
        </div>
    </div>
</template>

<script>
import {BButton, VBPopover} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default{
   components:{
    BButton,
   },
   directives:{
    'b-popover': VBPopover, 
     Ripple,
   }
}
</script>

<style>
.section-title{
    color:white;
}
</style>
