<template>
    <div class="page-layout">
        <header-vue />
          <!-- <router-view :key="$route.fullPath"/> -->
          <router-view class="router-view" />
        <footer-vue class="footer" />
    </div>
</template>

<script>
import HeaderVue from "../components/Header.vue";
import FooterVue from "../components/Footer.vue";
import router from "@/router"

export default{
    components:{
        HeaderVue,
        FooterVue,
    },
    data(){
        return {

        }
    }
}
</script>

<style>
/*Stick the footer to bottom*/
.page-layout{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

.router-view{
    flex: 1;
}

.footer{
    margin-top: auto;
}
</style>

