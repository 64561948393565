export function getImgPath(path){
    return process.env.VUE_APP_IMAGE_URL+path;
    //return localStorage.getItem('server')+'/storage/'+path;
}

/**
 * This function recursively traverses an object or array and returns a single string
 * that concatenates all string values found in the object or array. If a string is passed,
 * it returns the original string.
 * 
 * @param {Object|Array|string} obj - The object, array, or string to process.
 * @returns {string} - The concatenated string of all string values found, or the original string.
 */
export function extractStrings(obj) {
    // If a string is passed, return the original string.
    if (typeof obj === 'string') {
        return obj;
    }

    // Initialize an empty string to store the result.
    let result = '';

    // Loop over each property in the object.
    for (let key in obj) {
        // If the property value is a string, add it to the result.
        if (typeof obj[key] === 'string') {
            result += obj[key] + ' ';
        } 
        // If the property value is an array, loop through each item and recursively
        // call the extractStrings function on each item.
        else if (Array.isArray(obj[key])) {
            obj[key].forEach(item => {
                result += extractStrings(item);
            });
        } 
        // If the property value is an object, recursively call the extractStrings
        // function on it.
        else if (typeof obj[key] === 'object') {
            result += extractStrings(obj[key]);
        }
    }

    // Return the final concatenated string.
    return result;
}

export function formatPrice(price) {
    let formatted = new Intl.NumberFormat('zh-HK', { style: 'currency', currency: 'HKD' }).format(price);
    return formatted.indexOf('.00') > 0 ? formatted.replace('.00', '') : formatted;
}